
import React from 'react'
import ForgotPassword from '../components/ForgotPassword';


function ForgotPasswordScreen () {
  return (
    <div className='ForgotPasswordScreen'>
      <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordScreen;