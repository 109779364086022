
import React from 'react'
import Login from '../components/Login';


function LoginScreen () {
  return (
    <div className='LoginScreen'>
      <Login />
    </div>
  )
}

export default LoginScreen;