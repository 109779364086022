
import React from 'react'
import ResetPassword from '../components/ResetPassword';

function ResetPasswordScreen () {
  return (
    <div className='ResetPasswordScreen'>
      <ResetPassword />
    </div>
  )
}

export default ResetPasswordScreen;