
import React from 'react'
import OnBoarding from '../components/OnBoarding';


function OnBoardingScreen () {
  return (
    <div className='OnBoarding' >
      <OnBoarding />
    </div>
  )
}

export default OnBoardingScreen;